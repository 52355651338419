import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetFav } from '../store/actions/cartActions/cartActions';

var useIsLoggedIn = function useIsLoggedIn() {
  var _useState = useState(true),
      loading = _useState[0],
      setLoading = _useState[1];

  var _useState2 = useState(),
      isLoggedIn = _useState2[0],
      setIsLoggedIn = _useState2[1];

  var _useState3 = useState(''),
      authToken = _useState3[0],
      setAuthToken = _useState3[1];

  var dispatch = useDispatch();
  useEffect(function () {
    var token = JSON.parse(localStorage.getItem('vincenttoken'));
    var items = JSON.parse(localStorage.getItem('vincentfav'));

    if (token != null) {
      setAuthToken(function () {
        return token;
      });
      setIsLoggedIn(function () {
        return true;
      });
    } else {
      setIsLoggedIn(function () {
        return false;
      });
    }

    setLoading(function () {
      return false;
    });
  }, []);
  return {
    loading: loading,
    isLoggedIn: isLoggedIn,
    token: authToken
  };
};

export default useIsLoggedIn;