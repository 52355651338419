//
export var backend = 'https://vincent-backend.herokuapp.com/api';
export var backendFile = 'https://api-vincentsphere.herokuapp.com';
export var production = 'https://api-vincentsphere.herokuapp.com/api';
export var dev = 'http://localhost:5000/api';
var domain = '';
var frontDev = 'https://vincents-sphere.vercel.app/';
export var frontend = frontDev;
var server = production;
export var sizes = {
  displayCard: {
    width: 300,
    height: 350
  }
};
export var categories = [{
  name: 'Scented Candles',
  target: 'Scented Candles',
  title: 'Handcrafted Scented Candles',
  subtitle: 'With over hundreds of fragrances to choose from'
}, {
  name: 'Bath Salts',
  target: 'Bath Salts',
  title: 'Aromatherapy Bath Salts',
  subtitle: 'To soothe your mind, body & spirit'
}, {
  name: 'Satin Masks',
  target: 'Satin Masks',
  title: 'Satin Masks',
  subtitle: 'Made from easy breathable, soft & smooth satin'
}, {
  name: 'Satin Scrunchies',
  target: 'Satin Scrunchies',
  title: 'Satin Scrunchies',
  subtitle: 'To keep your hair healthy, silky & smooth'
}, {
  name: 'Dream Catchers',
  target: 'Dream Catchers',
  title: 'Dream Catchers',
  subtitle: 'For a night of healthy sleep'
}];
export var api = {
  homeproductcategories: "".concat(server, "/categories/homecat"),
  productcategories: "".concat(server, "/categories/cat"),
  products: "".concat(server, "/products"),
  search: "".concat(server, "/search"),
  login: "".concat(server, "/login"),
  register: "".concat(server, "/register"),
  order: "".concat(server, "/order"),
  userorder: "".concat(server, "/order/userorder"),
  //change product picture
  changeProductPicture: "".concat(server, "/products/changepicture"),
  allusers: "".concat(server, "/profile/getallusers"),
  profile: "".concat(server, "/profile"),
  anUser: "".concat(server, "/profile/getanuser"),
  userByMail: "".concat(server, "/profile/getuserbymail"),
  editRole: "".concat(server, "/profile/editrole"),
  editUser: "".concat(server, "/profile/edituser"),
  sendOtp: "".concat(server, "/profile/sendotp"),
  verifyOtp: "".concat(server, "/profile/verifyotp"),
  resetPassword: "".concat(server, "/profile/resetpassword"),
  changePassword: "".concat(server, "/profile/changePassword"),
  getAllUsers: "".concat(server, "/profile/getallusers"),
  createProduct: "".concat(server, "/products/createproduct"),
  getAllOrders: "".concat(server, "/order/getallorders"),
  changeSeen: "".concat(server, "/order/changeSeen"),
  payment: "".concat(server, "/payment"),
  explore: "".concat(server, "/explore"),
  dashboard: "".concat(server, "/dashboard"),
  upload: "".concat(server, "/upload"),
  //review route
  review: "".concat(server, "/review"),
  addWish: "".concat(server, "/review/addtowishlist"),
  deleteWish: "".concat(server, "/review/deletefromwishlist"),
  getWish: "".concat(server, "/review/getwishlist"),
  config: {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  },
  fileConfig: {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
};
export var general = {
  takaSymbol: '৳'
};
export var colors = {
  pink: '#fb3290'
};